import { useContext } from 'react';
import { ActionCableNotification } from '../types';
import { CableContext } from './cableContext';

interface useNotificationProps {
  channel: string;
  onReceived: (data: ActionCableNotification) => void;
}

export function useNotification({ channel:subscribedChannel, onReceived }: useNotificationProps) : void {
  const { socket } = useContext(CableContext);

  if(socket) {
    socket.addEventListener('message', (event) => {
      const data = JSON.parse(event.data)

      if (data.identifier) {
        const { channel } = JSON.parse(data.identifier)

        if (channel === subscribedChannel && data.message && !data.type) {
          onReceived(data);
        }
      }
    });
  }
}