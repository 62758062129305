import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import fetch from 'isomorphic-unfetch'
import { setContext } from 'apollo-link-context';
import { getAccessTokenFromToken } from './lib/token'
import { createUploadLink } from 'apollo-upload-client'

const httpLink = createUploadLink({
  uri: process.env.NEXT_PUBLIC_API_URL,
  credentials: 'same-origin',
  fetch
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getAccessTokenFromToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

export default function createApolloClient(initialState, ctx) {
  // The `ctx` (NextPageContext) will only be present on the server.
  // use it to extract auth headers (ctx.req) or similar.
  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    //@ts-ignore
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  })
}
