import { createContext } from "react";

interface PermissionContextType {
  isAllowedTo: (permission: string, franchiseId?: number) => boolean;
}

export const PermissionContext = createContext({
  isAllowedTo: (_permission: string, _franchiseId?: number) => true
} as PermissionContextType);

export const PermissionProvider = PermissionContext.Provider;
export const PermissionConsumer = PermissionContext.Consumer;