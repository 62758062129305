import { useContext, useEffect } from 'react';
import ManagerNavigation from './sidebar/ManagerNavigation';
import CommishNavigation from './sidebar/CommishNavigation';
import { AuthContext } from '../lib/authContext';
import Notification, { NotificationObject } from './library/Notification';
import { Fragment, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  ChevronUpIcon,
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useNotification } from '../lib/useNotification';
import FranchiseLogo from './franchise/FranchiseLogo';


type Props = {
  children?: React.ReactNode;
}

const AppLayout = ({ children }: Props) : JSX.Element => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [updateCount, setCount] = useState(0);
  const [notifications, setNotifications] = useState<NotificationObject[]>([])
  const { user, authenticated } = useContext(AuthContext)
  const { logout } = useContext(AuthContext)
  const router = useRouter()

  useEffect(() => {
    if (sidebarOpen) {
      setSidebarOpen(false);
    }
  }, [router.asPath]);

  function markAllAsSeen(): void {
    // window.Headway.markAllSeen();
    setCount(0);
  }

  const signOut = () : void => {
    localStorage.removeItem("token")
    logout()
    router.push("/login")
  }

  const notificationReceived = (data: any) : void => {
    console.log('NEW NOTIFICATION: ', data)
    setNotifications([...notifications, {...data?.message}])
  }

  useNotification({ channel: 'NotificationsChannel', onReceived: notificationReceived })

  if(authenticated) {
    return (
      <>
        <div>
          <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
              </Transition.Child>

              <div className="fixed inset-0 flex z-40">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full bg-gray-800">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 right-0 -mr-12 pt-2">
                        <button
                          type="button"
                          className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                          onClick={() => setSidebarOpen(false)}
                        >
                          <span className="sr-only">Close sidebar</span>
                          <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                      <div className="flex-shrink-0 flex items-center px-4">
                        <img src='/images/invitationalphoto.png' className='h-12 w-auto' />
                      </div>
                      <nav className="mt-5 px-2 space-y-1">
                        <ManagerNavigation />
                        {user && user.roles.includes("commish") &&
                          <CommishNavigation />
                        }
                      </nav>
                    </div>
                    <div className="flex-shrink-0 flex bg-gray-700 p-4 relative">
                      <Menu>
                        <Menu.Button className="flex-shrink-0 w-full group block">
                          <div className="flex items-center justify-between">
                            <div className='flex items-center'>
                              <FranchiseLogo franchise={user.franchise} />
                              <div className="ml-3 text-left">
                                <p className="text-sm font-medium text-white">{user.name}</p>
                                <p className="text-xs font-medium text-gray-300">{user.franchise.name}</p>
                              </div>
                            </div>
                            <ChevronUpIcon className='h-6 w-6 text-gray-500 headway-updates' />
                          </div>
                        </Menu.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="origin-top-right absolute bottom-full right-0 mt-2 mr-4 mb-4 w-32 rounded-md shadow-lg py-1 bg-gray-700 ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <Menu.Item>
                              <a href='https://headwayapp.co/dynos-football-release-notes' target='_blank' rel="noreferrer" onClick={markAllAsSeen} className='block pl-4 w-full py-2 text-sm text-white hover:bg-gray-600 headway-updates'>
                                Updates
                                {updateCount > 0 && <span className='w-2.5 h-2.5 rounded-full bg-primary-600 inline-block ml-4'></span>}
                              </a>
                            </Menu.Item>
                            <Menu.Item>
                              <a href='#' className='block pl-4 w-full py-2 text-sm text-white hover:bg-gray-600' onClick={signOut}>Sign Out</a>
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
                <div className="flex-shrink-0 w-14">{/* Force sidebar to shrink to fit close icon */}</div>
              </div>
            </Dialog>
          </Transition.Root>

          {/* Static sidebar for desktop */}
          <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex-1 flex flex-col min-h-0 bg-gray-800">
              <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                <div className="flex items-center flex-shrink-0 px-4">
                  <img src='/images/invitationalphoto.png' className='h-12 w-auto' />
                  <h2 className='text-white ml-4 uppercase'>Invitational Dynasty League</h2>
                </div>
                <nav className="mt-5 flex-1 px-2 space-y-1">
                  <ManagerNavigation />
                  {user && user.roles.includes("commish") &&
                    <CommishNavigation />
                  }
                </nav>
              </div>
              <div className="flex-shrink-0 flex bg-gray-700 p-4 relative">
                <Menu>
                  <Menu.Button className="flex-shrink-0 w-full group block">
                    <div className="flex items-center justify-between">
                      <div className='flex items-center'>
                        <FranchiseLogo franchise={user.franchise} />
                        <div className="ml-3 text-left">
                          <p className="text-sm font-medium text-white">{user.name}</p>
                          <p className="text-xs font-medium text-gray-300">{user.franchise.name}</p>
                        </div>
                      </div>
                      <ChevronUpIcon className='h-6 w-6 text-gray-500 headway-updates' />
                    </div>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute bottom-full right-0 mt-2 mr-4 mb-4 w-32 rounded-md shadow-lg py-1 bg-gray-700 ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        <a href='https://headwayapp.co/dynos-football-release-notes' target='_blank' rel="noreferrer" onClick={markAllAsSeen} className='block pl-4 w-full py-2 text-sm text-white hover:bg-gray-600 headway-updates'>
                          Updates
                          {updateCount > 0 && <span className='w-2.5 h-2.5 rounded-full bg-primary-600 inline-block ml-4'></span>}
                        </a>
                      </Menu.Item>
                      <Menu.Item>
                        <a href='#' className='block pl-4 w-full py-2 text-sm text-white hover:bg-gray-600' onClick={signOut}>Sign Out</a>
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
          <div className="md:pl-64 flex flex-col flex-1 min-h-screen">
            <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100">
              <button
                type="button"
                className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <main className="flex-1">
              <div className="py-6">
                {children}
              </div>
            </main>
            <>
              {/* Global notification live region, render this permanently at the end of the document */}
              <div
                aria-live="assertive"
                className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-50"
              >
                <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
                  {notifications.map((notification) => (
                    <Notification key={notification.id} data={notification} />
                  ))}
                </div>
              </div>
            </>
          </div>
        </div>
        <Script strategy='afterInteractive' src="https://cdn.headwayapp.co/widget.js"></Script>
      </>
    )
  } else {
    return (
      <div>{children}</div>
    )
  }
}

export default AppLayout;
