import React from 'react';
import { AuthenticatedUserFranchise } from '../../lib/authContext';
import { Franchise_franchise } from '../../__generated__/Franchise';
import { Franchises_franchises } from '../../__generated__/Franchises';
import { Transaction_transaction_assets_PlayerAsset_from } from '../../__generated__/Transaction';
import { TransactionSearch_allTransactions_transactions_assets_PlayerAsset_from } from '../../__generated__/TransactionSearch';

type FranchiseLogoProps = {
  franchise: Franchise_franchise | Franchises_franchises | AuthenticatedUserFranchise | TransactionSearch_allTransactions_transactions_assets_PlayerAsset_from | Transaction_transaction_assets_PlayerAsset_from | undefined;
}

const FranchiseLogo = ({ franchise }: FranchiseLogoProps) : JSX.Element => {
  if(franchise && franchise.logo) {
    return (
      <img data-testid="custom-logo" className="h-12 w-12 rounded-full object-cover" src={franchise.logo} alt=""/>
    )
  } else {
    return (
      <span data-testid="stock-logo" className="h-12 w-12 overflow-hidden rounded-full bg-gray-100">
        <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
        </svg>
      </span>
    )
  }
}

export default FranchiseLogo;
