import { AcademicCapIcon, ArrowsRightLeftIcon, ArrowsUpDownIcon, BanknotesIcon, UserIcon } from "@heroicons/react/24/outline";
import { TransactionKind } from "../../__generated__/globalTypes";

interface TransactionIconProps {
  kind: TransactionKind | string;
  className: string;
}

const TransactionIcon = ({ kind, className }: TransactionIconProps) : JSX.Element => {
  return (
    <>
      {(() => {
        switch(kind) {
          case TransactionKind.Waiver:
            return <ArrowsUpDownIcon className={className} data-testid='waiver-icon' />
          case TransactionKind.Trade:
            return <ArrowsRightLeftIcon className={className} data-testid='trade-icon'  />
          case TransactionKind.Negotiation:
            return <BanknotesIcon className={className} data-testid='negotiation-icon'  />
          case TransactionKind.DraftPickTransaction:
            return <AcademicCapIcon className={className} data-testid='draft-pick-icon'  />
          default:
            return <UserIcon className={className} data-testid='user-icon'  />
        }
      })()}
    </>
  )
}

export default TransactionIcon;