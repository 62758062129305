import { withApollo } from '../lib/apollo'
import { AuthenticatedUser, AuthProvider } from '../lib/authContext'
import { PermissionProvider } from '../lib/permissionContext'
import { useState, useEffect } from 'react'
import { getUserFromToken, getDecodedToken, getAccessTokenFromToken } from '../lib/token'
import { isCurrentVersion, missingVersion, setToCurrentVersion } from '../lib/version'
import { useRouter } from 'next/router'
import AppLayout from '../components/AppLayout'
import { ThemeProvider } from 'styled-components'
import theme from '../lib/theme';
import '../styles/globals.css'
import { CableProvider } from '../lib/cableContext'
import { AppProps } from 'next/app'

const emptyUser : AuthenticatedUser = {
  id: null,
  name: null,
  email: null,
  roles: [],
  franchise: {
    id: null,
    name: null,
    logo: null,
    motto: null
  },
  league_id: null,
}

function App({pageProps, Component}: AppProps) : JSX.Element {
  const [user, setUser] = useState<AuthenticatedUser>(emptyUser)
  const [token, setToken] = useState<string>('')
  const [authenticated, setAuthenticated] = useState(false)
  const [socket, setSocket] = useState<WebSocket|null>(null)
  const router = useRouter()

  const login = () : void => {
    setUser(getUserFromToken())
    setToken(getAccessTokenFromToken())
    setAuthenticated(true)
  }

  const logout = () : void => {
    setUser(emptyUser)
    setAuthenticated(false)
  }

  const isAllowedTo = (permission:string, franchiseId?:number) : boolean => {
    if (franchiseId) {
      if(parseInt(user.franchise.id || '0') !== franchiseId) {
        return false
      } else {
        return true
      }
    }

    return (user && user.roles?.includes(permission))
  }

  const checkVersionNumber = () : void => {
    if(missingVersion()) {
      console.log("set missing version")
      setToCurrentVersion();
    }

    if(!isCurrentVersion()) {
      console.log("version mismatch")
      logout();
      router.push("/login")
    }
  }

  const connectSocket = () : void => {
    const cableUrl = process.env.NEXT_PUBLIC_SOCKET_URL ?? 'ws://localhost:5000/cable'
    const cable = new WebSocket(`${cableUrl}?token=${token}`)

    cable.onopen = () => {
      cable.send(JSON.stringify({
        command: 'subscribe',
        identifier: JSON.stringify({
          channel: 'NotificationsChannel',
          league_id: '1'
        })
      }))
    }

    setSocket(cable)
  }

  useEffect(() => {
    if(!authenticated) {
      if(getDecodedToken()) {
        login();
        checkVersionNumber();
      }
      else {
        router.push("/login")
      }
    }
  }, []);

  useEffect(() => {
    if(token) {
      connectSocket()
    }
  }, [token])

  return (
    <AuthProvider value={{user: user, authenticated: authenticated, accessToken: token, login: login, logout: logout}}>
      <CableProvider value={{socket: socket}}>
        <PermissionProvider value={{isAllowedTo: isAllowedTo}}>
          <ThemeProvider theme={theme}>
            <AppLayout>
              <Component {...pageProps} />
            </AppLayout>
          </ThemeProvider>
        </PermissionProvider>
      </CableProvider>
    </AuthProvider>
  )
}

export default withApollo()(App);
