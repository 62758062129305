import Link from 'next/link';
import {
  HomeIcon,
  UsersIcon,
  ClockIcon,
  IdentificationIcon,
  ClipboardDocumentListIcon,
  UserGroupIcon
} from '@heroicons/react/24/outline'
import { classNames } from '../../lib/utils';
import { useRouter } from 'next/router';
import { useContext } from 'react';
import { AuthContext } from '../../lib/authContext';

type HeroIcon = (props: React.ComponentProps<'svg'>) => JSX.Element;

type NavigationItem = {
  icon: HeroIcon;
  name: string;
  href: string;
  matches: Array<string>;
}

function isCurrent (matches: Array<string>, currentPath: string): boolean {
  //ew get an actual regex boi in here
  if(currentPath === '/') {
    return matches.some(href => currentPath.match(href))
  } else {
    return matches.some(href => currentPath.match(href) && href !== '/')
  }
}

const ManagerNavigation = () : JSX.Element => {
  const router = useRouter()
  const { user } = useContext(AuthContext)

  const navigation: Array<NavigationItem> = [
    { name: 'League', href: '/', icon: HomeIcon, matches: ['/'] },
    { name: 'Franchises', href: '/franchises', icon: UserGroupIcon, matches: ['/franchises'] },
    { name: 'My Franchise', href: `/franchises/${user?.franchise?.id}`, icon: IdentificationIcon, matches: [`/franchises/${user?.franchise?.id}`] },
    { name: 'Transactions', href: '/transactions', icon: ClockIcon, matches: ['/transactions'] },
    { name: 'Players', href: '/players', icon: UsersIcon, matches: ['/players'] },
    { name: 'Drafts', href: '/drafts', icon: ClipboardDocumentListIcon, matches: ['/drafts'] },
  ]

  return (
    <div>
      {navigation.map((item: NavigationItem) => (      
        <Link
          key={item.name}
          href={item.href}
          passHref                            
        >
          <a
            className={classNames(
              (isCurrent(item.matches, router.pathname))
                ? 'bg-gray-900 text-white'
                : 'text-gray-300 hover:bg-gray-700 hover:text-white',
              'group flex items-center px-2 py-2 text-base font-medium rounded-md cursor-pointer',
            )}
          >
            <item.icon
              className={classNames(
                isCurrent(item.matches, router.pathname) 
                  ? 'text-gray-300' 
                  : 'text-gray-400 group-hover:text-gray-300',
                'mr-4 flex-shrink-0 h-6 w-6'
              )}
              aria-hidden="true"
            />
            {item.name}
          </a>
        </Link>
      ))}
    </div>
  )
}

export default ManagerNavigation;
