const theme = {
  colors: {
    primary: '#27AE60',
    black: '#000',
    grayOne: '#333',
    grayTwo: '#4F4F4F',
    grayThree: '#828282',
    grayFour: '#BDBDBD',
    graySix: '#F2F2F2',
    red: '#EB5757',
    orange: '#F2994A',
    yellow: '#F2C94C',
    green: '#27AE60',
    blue: '#2F80ED'
  },
  fontSizes: [
    '12px',
    '14px',
    '16px',
    '18px',
    '24px',
    '32px',
    '48px'
  ],
  fontWeights: [0, 400, 600, 700, 900],
  lineHeights: [0, 1, 1.25, 1.56, 1.95, 2.44],
  positionColors: {
    QB: '#EB5757',
    RB: '#F2994A',
    WR: '#F2C94C',
    TE: '#27AE60',
    K: '#2F80ED',
    DEF: '#4F4F4F',
  }
}

export default theme;
