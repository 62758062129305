import { ExtendedTransactionAssetInput } from "../types";
import { DraftPicks_draftPicks } from "../__generated__/DraftPicks";
import { TransactionAssetInput } from "../__generated__/globalTypes";

export function classNames(...classes: string[]) : string {
  return classes.filter(Boolean).join(' ')
}

export function nFormatter(num: number, digits = 0): string {
  const posNeg = num < 0 ? '-' : '';
  num = Math.abs(num);
  
  const symbols = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
  ];

  if (num < 1) return num.toString();
  const regex = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const i = symbols.reverse().findIndex(s => num >= s.value);
  return posNeg + (num / symbols[i].value).toFixed(digits).replace(regex, '$1') + symbols[i].symbol;
}

export function draftPickTraded(draftPick: DraftPicks_draftPicks) : boolean {
  return draftPick.franchise?.id !== draftPick.originalFranchise?.id
}

export function removeTempId(input: ExtendedTransactionAssetInput) : TransactionAssetInput {
  const { tempId, ...rest } = input;
  return rest;
}

export function getTempId() : string {
  return Math.random().toString(36).substr(2, 9);
}