import { createContext } from "react";

interface CableContextType {
  socket: WebSocket | null;
}

export const CableContext = createContext({
  socket: null
} as CableContextType);

export const CableProvider = CableContext.Provider;
export const CableConsumer = CableContext.Consumer;
