import jwt from 'jsonwebtoken'

export const getDecodedToken = () => {
  const token = localStorage.getItem('token')
  return (token === null) ? null : jwt.verify(token, 'fantasyisreality')
}

export const getUserFromToken = () => {
  const token = getDecodedToken()
  return token?.user
}

export const getAccessTokenFromToken = () => {
  const token = getDecodedToken()
  return token?.accessToken
}
