import { createContext } from "react";

export interface AuthenticatedUserFranchise {
  id: string | null;
  name: string | null;
  motto: string | null;
  logo: string | null;
}

export interface AuthenticatedUser {
  id: number | null;
  name: string | null;
  roles: string[];
  email: string | null;
  franchise: AuthenticatedUserFranchise;
  league_id: number | null;
}

interface AuthContextType {
  authenticated: boolean;
  accessToken: string;
  login: () => void;
  logout: () => void;
  user: AuthenticatedUser;
}

export const AuthContext = createContext({
  authenticated: false,
  user: {
    id: null,
    name: null,
    email: null,
    roles: [],
    franchise: {
      id: null,
      name: null,
      motto: null,
      logo: null
    },
    league_id: null
  },
  accessToken: "",
  login: () => {},
  logout: () => {},
} as AuthContextType);

export const AuthProvider = AuthContext.Provider;
export const AuthConsumer = AuthContext.Consumer;
