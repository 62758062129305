/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum TransactionAssetKind {
  AdjustmentAsset = "AdjustmentAsset",
  DraftPickAsset = "DraftPickAsset",
  PlayerAsset = "PlayerAsset",
}

export enum TransactionKind {
  DraftPickTransaction = "DraftPickTransaction",
  Negotiation = "Negotiation",
  OffseasonNegotiation = "OffseasonNegotiation",
  Trade = "Trade",
  Waiver = "Waiver",
}

export enum TransactionStatus {
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
  DRAFT = "DRAFT",
  PENDING = "PENDING",
}

/**
 * Autogenerated input type of CreateTransactionAsset
 */
export interface CreateTransactionAssetInput {
  transactionId: string;
  asset: TransactionAssetInput;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of CreateTransaction
 */
export interface CreateTransactionInput {
  assets: TransactionAssetInput[];
  type: TransactionKind;
  note?: string | null;
  approve?: boolean | null;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of DeleteTransactionAsset
 */
export interface DeleteTransactionAssetInput {
  transactionAssetId: string;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of FavoritePlayer
 */
export interface FavoritePlayerInput {
  id: string;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of SaveFranchise
 */
export interface SaveFranchiseInput {
  id: string;
  name: string;
  motto?: string | null;
  logo?: any | null;
  abbr?: string | null;
  color?: string | null;
  phone?: string | null;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of SkipDraftPick
 */
export interface SkipDraftPickInput {
  id: string;
  clientMutationId?: string | null;
}

export interface TransactionAssetInput {
  tempId?: string | null;
  designation?: number | null;
  salary?: number | null;
  to?: number | null;
  type: TransactionAssetKind;
  playerId?: number | null;
  contractId?: number | null;
  draftPickId?: number | null;
  adjustmentId?: number | null;
  note?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
