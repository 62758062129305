
export const getVersion = () : string | null => {
  return localStorage.getItem('settings_version')
}

export const missingVersion = () : boolean => {
  return getVersion() === null || getVersion() === undefined || getVersion() === '' || getVersion() === 'undefined'
}

export const setVersion = (version:string) : void => {
  return localStorage.setItem('settings_version', version)
}

export const getCurrentVersion = () : string => {
  return process.env.NEXT_PUBLIC_SETTINGS_VERSION_NUMBER || '0.0.0';
}

export const setToCurrentVersion = () : void => {
  return setVersion(getCurrentVersion())
}

export const isCurrentVersion = () : boolean => {
  return getVersion() === getCurrentVersion();
}